/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-08-09 13:05:19
 * @LastEditors: tanwei
 * @LastEditTime: 2020-11-24 16:33:21
 * @FilePath: /open-platform/src/redux/actions/commonAction.ts
 */
import { Action } from 'redux';
import { CollapsedType, UserInfoType, DictAllType, MenusListType, NavigationModeType, IsAllFullScreenType } from '@/stores/type/commonType';
import { MENU_COLLAPSED, USER_INFO, DICT_ALL_DATA, MENU_LIST, NAVIGATION_MODE, IS_All_FULL_SCREEN } from '@/stores/actionTypes';

interface CollapsedAction extends Action<MENU_COLLAPSED> {
    collapsed: Partial<CollapsedType>
}
interface UserInfoAction extends Action<USER_INFO> {
    userInfo: Partial<UserInfoType>
}
interface DictAllAction extends Action<DICT_ALL_DATA> {
    dictAllData: Partial<DictAllType>
}
interface MenusListAction extends Action<MENU_LIST> {
    menusList: Partial<MenusListType[]>
}

interface NavigationModeAction extends Action<NAVIGATION_MODE> {
    navigationMode: Partial<NavigationModeType>
}

interface IsAllFullScreenAction extends Action<IS_All_FULL_SCREEN> {
    isAllFullScreen: Partial<IsAllFullScreenType>
}

export const collapsedClick = (collapsed: CollapsedType): CollapsedAction => ({
    type: MENU_COLLAPSED,
    collapsed,
});
export const setUserInfo = (userInfo: UserInfoType): UserInfoAction => ({
    type: USER_INFO,
    userInfo,
});

export const setDictAllData = (dictAllData: DictAllType): DictAllAction => ({
    type: DICT_ALL_DATA,
    dictAllData,
});

export const setMenusList = (menusList: MenusListType[]): MenusListAction => ({
    type: MENU_LIST,
    menusList,
});

export const setNavigationMode = (navigationMode: NavigationModeType): NavigationModeAction => ({
    type: NAVIGATION_MODE,
    navigationMode,
});

export const setIsAllFullScreen = (isAllFullScreen: IsAllFullScreenType): IsAllFullScreenAction => ({
    type: IS_All_FULL_SCREEN,
    isAllFullScreen,
});

export type CommonAction = CollapsedAction | UserInfoAction | DictAllAction | MenusListAction | NavigationModeAction | IsAllFullScreenAction;
