/*
 * @Descripttion:
 * @Author: tanwei
 * @Date: 2021-03-07 14:46:34
 * @LastEditors: tanwei
 * @LastEditTime: 2021-03-08 15:45:22
 * @FilePath: /open-platform/src/serve/common.ts
 */


import config from '@/config';
const userlogin = (data) => ({
    url: `${config.apiWebPath}/user/login`,
    method: 'post',
    data,
});

const getUserInfo = () => ({
    url: `${config.apiWebPath}/user/get`,
    method: 'get',
});
const getAuth = () => ({
    url: `${config.apiWebPath}/user/auth`,
    method: 'get',
});
const getDictAll = () => ({
    url: `${config.apiAdminPath}/dict/all`,
    method: 'get',
});
const sendSms = (data) => ({
    url: '/ali/sms/send',
    method: 'post',
    data,
});
const getOssSignature = (data?) => ({
    url: '/ali/oss/get/signature',
    method: 'get',
    data,
});

// const uploadFile = (data) => ({
//     url: `${config.apiWebPath}/uploadFile/upImg`,
//     method: 'post',
//     data,
// });

const setSkinConfig = (data) => ({
    url: `${config.apiWebPath}/user/set/skin`,
    method: 'put',
    data: { skin: JSON.stringify(data?.skin) },
});

export default {
    userlogin,
    getUserInfo,
    getAuth,
    getDictAll,
    sendSms,
    // uploadFile,
    getOssSignature,
    setSkinConfig
};