/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-25 15:00:27
 * @LastEditors: tanwei
 * @LastEditTime: 2021-02-08 15:06:08
 * @FilePath: /open-platform/src/components/FormElements/WrapperInputNumber.tsx
 */
import React, { FC } from 'react';
import { InputNumberProps } from 'antd/lib/input-number';
import {
    ProFormDigit
} from '@ant-design/pro-form';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';


interface BaseProps<T> extends ProFormItemProps<T> {
    autoComplete?: string
}
const defaultConfig = {
    autoComplete: 'off',
    style: { width: '100%' },
    parser: value => typeof value === 'string' ? value.replace(/[^\d]/g, '') : ''
};
const WrapperInputNumber: FC<BaseProps<InputNumberProps>> = (props) => {
    const { fieldProps, ...restProps } = props;
    if (fieldProps?.max && (fieldProps?.min || fieldProps?.min === 0) && !restProps.tooltip) {
        restProps.tooltip = `输入框区间为：${fieldProps?.min}-${fieldProps?.max}`;
    }
    return <ProFormDigit
        {...restProps}
        fieldProps={{
            ...defaultConfig,
            ...fieldProps
        }}
    />;
};

export default WrapperInputNumber;
