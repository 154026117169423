/* eslint-disable require-atomic-updates */
/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-26 15:54:23
 * @LastEditors: tanwei
 * @LastEditTime: 2021-03-20 19:09:40
 * @FilePath: /open-platform/src/components/FormElements/WrapperOssUpload.tsx
 */
import React, { FC, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ProFormUploadButton, ProFormUploadDragger } from '@ant-design/pro-form';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';
import { UploadProps } from 'antd/lib/upload';
import { ButtonProps } from 'antd/lib/button';
import WrapperModal from '@/components/WrapperModal';
import { useRequest } from 'ahooks';
import { getLocalStorageItem } from '@/utils/utils';
import commonRequest from '@/serve/common';
interface BaseUploaProps<T> extends ProFormItemProps<T> {
    icon?: React.ReactNode;
    title?: React.ReactNode;
    listType?: UploadProps['listType'];
    action?: UploadProps['action'];
    accept?: UploadProps['accept'];
    draggable?: boolean;
    description?: React.ReactNode;
    /**
     * OSSParamr：OSS请求参数
     */
    OSSParamr?: object;
    /**
     * 是否支持预览
     */
    supporPreview?: boolean
    /**
     * max 最大上传个数
     */
    max?: number;
    onChange?: UploadProps['onChange'];
    value?: UploadProps['fileList'];

    /**
     * fieldMaxSize 文件最大值 
     * 单位 MB
     */
    fieldMaxSize?: number
    buttonProps?: ButtonProps;
    disabled?: ButtonProps['disabled'];
}
export interface OSSDataType {
    accessid: string
    policy: string
    signature: string
    callback: string
    host: string
    expire: number
    dir: string
}
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

// ACCEPT = {
//     zip: 'application/zip,application/x-zip,application/x-zip-compressed',
//     pdf: 'application/pdf',
//     excel: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     image: 'image/jpeg,image/bmp,image/png,image/gif',
//     word: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//     ppt: 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
//   },
const WrapperOssUpload: FC<BaseUploaProps<UploadProps>> = ({ OSSParamr, title, icon, description, draggable = false, fieldMaxSize, onChange, supporPreview = false, fieldProps, ...restProps }) => {
    const [OSSData, setOSSData] = useState<OSSDataType>();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');

    const aliOssSignature = useRequest(commonRequest.getOssSignature, { manual: true });
    const newValue: any = fieldProps?.fileList || restProps?.value;
    const newOnChange: any = fieldProps?.onChange || onChange;
    const newListType = fieldProps?.listType || restProps?.listType;

    const beforeUpload = async file => {
        const isMaxBol = fieldMaxSize && file.size / 1024 / 1024 < fieldMaxSize;
        // 使用promise阻止验证错误继续上传问题
        if (fieldMaxSize && !isMaxBol) {
            return new Promise((resolve, reject) => {
                window.message.error(`文件大小不能超过${fieldMaxSize}MB`);
                return reject(false);
            });
        }
        // 使用promise阻止验证错误继续上传问题
        try {
            console.log(OSSParamr, 'OSSParamr');

            const res = await aliOssSignature?.run(OSSParamr);
            const { status, data } = res;
            if (status === 'success') {
                setOSSData(data || {});
                const suffix = file?.name.slice(file?.name.lastIndexOf('.'));
                const filename = Date.now() + suffix;
                file.key = data.dir + filename;
            } else {
                return new Promise((resolve, reject) => reject(false));
            }
        } catch (err) {
            window.message.error('获取oss信息错误');
            return new Promise((resolve, reject) => reject(false));
        }
        return file;
    };
    const getExtraData = (file): object | Promise<object> => {
        return {
            key: file?.key,
            originalName: file?.name,
            OSSAccessKeyId: OSSData?.accessid,
            policy: OSSData?.policy,
            Signature: OSSData?.signature,
            success_action_status: '200', //让服务端返回200，不设置则默认返回204
            callback: OSSData?.callback,
        };
    };
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const onChangeWrapper = ({ file, fileList }) => {
        if (file?.status === 'done' || file?.status === 'uploading' || file?.status === 'removed') {
            if (file?.status === 'done') {
                if (file?.response?.status === "success") {
                    window.message.success('上传成功');
                    const newFileList = fileList?.filter((v) => v.status !== 'error');
                    if (newOnChange) {
                        const formatFileList = normalize(newFileList);
                        newOnChange(formatFileList);
                    }
                } else {
                    window.message.error('上传接口错误');
                }
            }
        } else {
            window.message.error('服务器上传错误');
        }
    };
    const onRemoveWrapper = file => {
        const files = fieldProps?.fileList?.filter(v => v?.uid !== file?.uid);
        if (newOnChange) {
            newOnChange(files);
        }
    };
    const normalize = (value, prevValue?, prevValues?) => {
        // 在form 收集值前过滤到value的失败文件
        let newValue: any[] = [];
        value?.forEach((v) => {
            console.log(v, 'v');

            // 数据格式处理
            if (v.status === 'done') {
                newValue.push({
                    uid: v?.uid,
                    name: v?.name,
                    status: 'done',
                    size: v?.response?.data?.fileSize,
                    type: v?.response?.data?.fileType,
                    url: v?.response?.data?.fileUrl,
                    fileMd5: v?.response?.data?.fileMd5,
                    ext: v?.response?.data?.ext,
                });
            }
        });
        return newValue;
    };
    fieldProps?.fileList && delete fieldProps.fileList;

    const newRestProps = {
        normalize: normalize,
        // validateTrigger: 'onClick',
        ...restProps,
        value: newValue,
        fieldProps: {
            name: 'file',
            action: OSSData?.host,
            headers: { 'token': getLocalStorageItem('token') || '' },
            onPreview: supporPreview ? handlePreview : undefined,
            data: getExtraData,
            beforeUpload,
            ...fieldProps,
            onChange: onChangeWrapper,
            onRemove: onRemoveWrapper,
        }
    };
    let newTitle = title || '点击上传';
    return (
        <div className={newListType === 'picture-card' && !draggable ? 't-upload-picture-card' : 't-upload'}>
            {newListType === 'picture-card' || draggable ?
                <ProFormUploadDragger
                    title={newTitle}
                    // title={newListType !== 'picture-card' || draggable ? title : false}
                    icon={newListType !== 'picture-card' || draggable ? icon : <PlusOutlined />}
                    description={newListType !== 'picture-card' || draggable ? description : false}
                    {...newRestProps}
                /> :
                <ProFormUploadButton
                    title={newTitle}
                    icon={icon}
                    {...newRestProps}
                />
            }
            <WrapperModal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </WrapperModal>
        </div>
    );
};

export default WrapperOssUpload;
