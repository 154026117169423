/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-11-26 16:56:50
 * @LastEditors: tanwei
 * @LastEditTime: 2021-02-28 20:32:56
 * @FilePath: /open-platform/src/components/FormElements/WrapperForm.tsx
 */
import React, { FC } from 'react';
import ProForm, { ProFormDateRangePicker } from '@ant-design/pro-form';
import { ProFormProps } from '@ant-design/pro-form/lib/index';
import WrapperInput, { WrapperInputPassword, WrapperInputTextArea } from './WrapperInput';
import WrapperInputNumber from './WrapperInputNumber';
import WrapperRadio from './WrapperRadio';
import WrapperSelect from './WrapperSelect';
import WrapperOssUpload from './WrapperOssUpload';
import WrapperUpload from './WrapperUpload';
import WrapperTreeSelect from './WrapperTreeSelect';

interface IProps extends ProFormProps {
    /** 提交表单且数据验证成功后回调事件 */
    // onFinish?: (values: any) => Promise<void>;
}
const WrapperForm: FC<IProps> = ({ children, ...restProps }) => {
    const [form] = ProForm.useForm();
    return (
        <ProForm
            form={form}
            submitter={{
                // 完全自定义整个区域
                render: false,
            }}
            layout='horizontal'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            {...restProps}
        >
            {children}
        </ProForm>
    );
};
export {
    WrapperForm as default,
    WrapperInput,
    WrapperInputPassword,
    WrapperInputTextArea,
    WrapperInputNumber,
    WrapperRadio,
    WrapperSelect,
    WrapperTreeSelect,
    WrapperUpload,
    WrapperOssUpload,
    ProFormDateRangePicker,
};