/* eslint-disable no-extra-parens */
import { parse } from 'qs';

/**
 * 判断是否为空
 * @param val
 * @returns {boolean}
 */
function validateNull(val) {
  if (typeof val === 'boolean') {
    return false;
  }
  if (typeof val === 'number') {
    return false;
  }
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true;
  }
  return false;
}


/**
 * 自定义区间随机数
 * @param {*} start 
 * @param {*} end 
 */
function getRandomNumberByRange(start = 1, end = 99999999999) {
  return Math.floor(Math.random() * (end - start) + start) + '';
}
/*
* 树形结构反查父节点
* */
function treeFindPath(tree, func, path: any[] = []) {
  if (!tree) return [];
  for (const data of tree) {
    // 这里按照你的需求来存放最后返回的内容吧
    path.push(data.name);
    if (func(data)) return path;
    if (data.children) {
      const findChildren = treeFindPath(data.children, func, path);
      if (findChildren.length) return findChildren;
    }
    path.pop();
  }
  return [];
}

const authority = arr => {
  // 传入值为数组,返回类似{authA:ture,authB:false}的对象，传入值为字符串直接返回true/false
  const authorityArr = getLocalStorageItem('auth') ? JSON.parse(getLocalStorageItem('auth')) : [];
  if (!authorityArr) {
    return false;
  }
  if (Array.isArray(arr)) {
    const authorityRes = {};
    arr.forEach(item => {
      authorityRes[item] = authorityArr.includes(item);
    });
    return authorityRes;
  }
  if (typeof arr === "string") {
    return authorityArr.includes(arr);
  }
  return false;
};
/**
 * 数组转树结构
 * @param {*} list 
 * @param {*} pid 
 */
function arrayToTree(list, pid = "0") {
  const tree = list.filter(item => item.pid === pid).map(item => ({
    ...item,
    children: arrayToTree(list, item.id),
  }));
  tree.forEach(val => validateNull(val.children) && delete val.children);
  return tree;
}

/**
 * 树转数组结构
 * @param {*} list 
 * @param {*} newArr 
 */
function treeToArray(list, newArr: any[] = []) {
  list.forEach((item: any) => {
    const { children } = item;
    if (children) {
      delete item.children;

      if (children.length) {
        newArr.push(item);
        return treeToArray(children, newArr);
      }
    }
    newArr.push(item);
  });
  return newArr;
}

/**
 * 获取localStorage的key
 * @param key
 */
const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key) || '';
};
/**
 * 设置localStorage的值
 * @param key
 * @param value
 */
const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};
/**
 * 清空localStorage
 */
const clearAllLocalStorage = () => {
  localStorage.clear();
};

/**
 * 获取url参数
 * @param search
 */
function urlQuery(search: string) {
  return parse(search?.replace('?', ''));
}

/**
 * treeSelect 格式化树key
 * @param treeNodeObj 转化的对象
 * @param formatValue title：需要转为title的参数，value：需要转化为value的参数
 * @param recursionBasis 递归的目标 默认children
 * @param targetDisabled 需要禁用的值
 */
export interface NewTreeNodeObjType {
  title: string
  value: string
  children?: NewTreeNodeObjType[]
  disabled?: boolean
}
export interface formatValue {
  title: string
  value: string
}
const formatTreeSelect = <T>(treeNodeObj: T, formatValue: formatValue, recursionBasis: string = 'children', targetDisabled?: string[] | string) => {
  const haveChildren = Array.isArray(treeNodeObj[recursionBasis]) && treeNodeObj[recursionBasis].length > 0;
  const title = treeNodeObj[formatValue?.title];
  const value = treeNodeObj[formatValue?.value];
  let newTreeNodeObj: NewTreeNodeObjType = {
    title,
    value,
    children: haveChildren && treeNodeObj[recursionBasis].map((val: T) => formatTreeSelect(val, formatValue, recursionBasis, targetDisabled))
  };
  if ((Array.isArray(targetDisabled) && targetDisabled?.includes(value)) || (typeof targetDisabled === "string" && targetDisabled === value)) {
    newTreeNodeObj.disabled = true;
    delete newTreeNodeObj[recursionBasis];
  }
  !haveChildren && delete newTreeNodeObj[recursionBasis];
  return newTreeNodeObj;
};

/**
 * removeTreeEmptyChildren 去除空children（后端说去不了 - -！） 
 * @param treeNodeObj 转化的对象
 * @param recursionBasis 递归的目标 默认children
 * @returns 
 */
const removeTreeEmptyChildren = <T>(treeNodeObj: T, recursionBasis: string = 'children',) => {
  const haveChildren = Array.isArray(treeNodeObj[recursionBasis]) && treeNodeObj[recursionBasis].length > 0;
  if (haveChildren) {
    treeNodeObj[recursionBasis].map((item: T) => removeTreeEmptyChildren(item));
  } else {
    delete treeNodeObj[recursionBasis];
  }
  return treeNodeObj;
};

export {
  getRandomNumberByRange,
  treeFindPath,
  authority,
  validateNull,
  arrayToTree,
  treeToArray,
  getLocalStorageItem,
  setLocalStorageItem,
  clearAllLocalStorage,
  urlQuery,
  formatTreeSelect,
  removeTreeEmptyChildren,
};
