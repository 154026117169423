import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
interface IPorps extends DropDownProps {
}

const WrapperDropdown: FC<IPorps> = (props) => {
    const { children, ...restProps } = props;

    return (
        <Dropdown
            {...restProps}
        >
            { children}
        </Dropdown >
    );
};

export default WrapperDropdown;
