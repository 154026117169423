/* eslint-disable require-atomic-updates */
/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-26 15:54:23
 * @LastEditors: tanwei
 * @LastEditTime: 2021-03-20 19:09:40
 * @FilePath: /open-platform/src/components/FormElements/wrapperUpload.tsx
 */
import React, { FC, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ProFormUploadButton, ProFormUploadDragger } from '@ant-design/pro-form';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';
import { UploadProps } from 'antd/lib/upload';
import { ButtonProps } from 'antd/lib/button';
import WrapperModal from '@/components/WrapperModal';
import { getLocalStorageItem } from '@/utils/utils';
interface BaseUploaProps<T> extends ProFormItemProps<T> {
    icon?: React.ReactNode;
    title?: React.ReactNode;
    listType?: UploadProps['listType'];
    action?: UploadProps['action'];
    accept?: UploadProps['accept'];
    draggable?: boolean;
    description?: React.ReactNode;
    /**
     * 是否支持预览
     */
    supporPreview?: boolean
    /**
     * max 最大上传个数
     */
    max?: number;
    onChange?: UploadProps['onChange'];
    value?: UploadProps['fileList'];

    /**
     * fieldMaxSize 文件最大值 
     * 单位 MB
     */
    fieldMaxSize?: number
    buttonProps?: ButtonProps;
    disabled?: ButtonProps['disabled'];
}
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const WrapperUpload: FC<BaseUploaProps<UploadProps>> = ({ title, icon, description, draggable = false, fieldMaxSize, onChange, supporPreview = false, fieldProps, ...restProps }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const newListType = fieldProps?.listType || restProps?.listType;

    const beforeUpload = async file => {
        const isMaxBol = fieldMaxSize && file.size / 1024 / 1024 < fieldMaxSize;
        // 使用promise阻止验证错误继续上传问题
        if (fieldMaxSize && !isMaxBol) {
            return new Promise((resolve, reject) => {
                window.message.error(`文件大小不能超过${fieldMaxSize}MB`);
                return reject(false);
            });
        }
        return file;
    };
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const actionUrl = process.env.REACT_APP_BASE_URL || '';
    const newRestProps = {
        ...restProps,
        fieldProps: {
            name: 'file',
            action: `${actionUrl}/open/uploadFile/upImg`,
            headers: { 'access-token': getLocalStorageItem('token') || '' },
            onPreview: supporPreview ? handlePreview : undefined,
            beforeUpload,
            ...fieldProps,
        }
    };
    let newTitle = title || '点击上传';
    return (
        <div className={newListType === 'picture-card' && !draggable ? 't-upload-picture-card' : 't-upload'}>
            {newListType === 'picture-card' || draggable ?
                <ProFormUploadDragger
                    title={newTitle}
                    icon={newListType !== 'picture-card' || draggable ? icon : <PlusOutlined />}
                    description={newListType !== 'picture-card' || draggable ? description : false}
                    {...newRestProps}
                /> :
                <ProFormUploadButton
                    title={newTitle}
                    icon={icon}
                    {...newRestProps}
                />
            }
            <WrapperModal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </WrapperModal>
        </div>
    );
};

export default WrapperUpload;
