/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-25 13:06:20
 * @LastEditors: tanwei
 * @LastEditTime: 2021-03-07 20:17:43
 * @FilePath: /open-platform/src/components/FormElements/WrapperSelect.tsx
 */
import React, { FC, memo } from 'react';
import { ProFormSelect } from '@ant-design/pro-form';
import { SelectProps } from 'antd/lib/select';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';
interface BaseSelectProps<T> extends ProFormItemProps<T> {
    valueEnum?: import("@ant-design/pro-utils").ProSchemaValueEnumObj | import("@ant-design/pro-utils").ProSchemaValueEnumMap | undefined;
    request?: import("@ant-design/pro-utils/lib/typing").ProFieldRequestData<{
        key?: string | number | undefined;
        dataIndex?: string | number | React.ReactText[] | undefined;
        valueType?: string | ((entity: unknown, type: import("@ant-design/pro-utils").ProSchemaComponentTypes) => string) | undefined;
        title?: string | number | boolean | {} | React.ReactElement<any, string | ((props: any) => React.ReactElement<any, string | any | (new (props: any) => React.Component<any, any, any>)> | null) | (new (props: any) => React.Component<any, any, any>)> | React.ReactNodeArray | React.ReactPortal | ((schema: any, type: import("@ant-design/pro-utils").ProSchemaComponentTypes, dom: React.ReactNode) => React.ReactNode) | null | undefined;
        tooltip?: string | undefined;
        tip?: string | undefined;
        render?: ((dom: React.ReactNode, entity: unknown, index: number, action: import("@ant-design/pro-utils").ProCoreActionType, schema: any) => React.ReactNode) | undefined;
        renderFormItem?: ((item: any, config: {
            value?: any;
            onChange?: ((value: any) => void) | undefined;
            onSelect?: ((value: any) => void) | undefined;
            type: import("@ant-design/pro-utils").ProSchemaComponentTypes;
            defaultRender: (newItem: any) => JSX.Element | null;
        }, form: import("antd/lib/form").FormInstance<any>) => React.ReactNode) | undefined;
        renderText?: ((text: any, record: unknown, index: number, action: import("@ant-design/pro-utils").ProCoreActionType) => any) | undefined;
        fieldProps?: any;
        valueEnum?: import("@ant-design/pro-utils").ProSchemaValueEnumObj | import("@ant-design/pro-utils").ProSchemaValueEnumMap | undefined;
        request?: import("@ant-design/pro-utils/lib/typing").ProFieldRequestData<any, any> | undefined;
        params?: {
            [key: string]: any;
        } | undefined;
        hideInDescriptions?: boolean | undefined;
    }, any> | undefined;
    options?: import("rc-select/lib/interface").OptionsType | undefined;
    mode?: "multiple" | "tags" | undefined;
    showSearch?: boolean | undefined;
}
const WrapperRadio: FC<BaseSelectProps<SelectProps<any>>> = ({ fieldProps, ...restProps }) => {
    return (
        <ProFormSelect
            {...restProps}
            fieldProps={{
                dropdownStyle: { maxHeight: 400, overflow: 'auto' },
                ...fieldProps
            }}
        />
    );
};

export default memo(WrapperRadio);
