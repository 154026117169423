/*
 * @Descripttion:
 * @Author: tanwei
 * @Date: 2021-01-30 16:25:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-23 22:50:37
 * @FilePath: /open-platform-admin/src/hooks/useGetAllConfig.tsx
 */
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { UserInfoType, MenusListType, NavigationModeType } from '@/stores/type/commonType';
import { setUserInfo, setDictAllData, setMenusList, setNavigationMode } from '@/stores/actions/commonAction';
import commonRequest from '@/serve/common';
const Index = () => {
    const dispatch = useDispatch();
    const { getUserInfo, getDictAll } = commonRequest;
    // const { getUserInfo, getDictAll, getAuth } = commonRequest;
    const userInfoRequest = useRequest(getUserInfo, { manual: true });
    const dictAllRequest = useRequest(getDictAll, { manual: true });
    // const getAuthRequest = useRequest(getAuth, { manual: true });
    const getUserMenus = async () => {
        // const res = await getAuthRequest.run();
        // console.log(res, 'authRes');
        const data: MenusListType[] = [
            {
                id: "1",
                pid: "0",
                title: "主页",
                route: "/", // 路由地址
                path: 'pages/index',// 组件路径
                icon: "HomeOutlined",
                type: '2', // 类型（1栏目，2菜单）
                isRoute: '1', // 是否左侧菜单显示
            },
            {
                id: "2",
                pid: "0",
                route: "/course",
                title: "课程管理",
                icon: "SettingFilled",
                type: '1',
                isRoute: '1',
            },
            {
                id: "2-1",
                pid: "2",
                title: "课程列表",
                route: "/course/management",
                path: 'pages/course/management',// 组件路径
                type: '2',
                isRoute: '1',
            },
            {
                id: "2-1-1",
                pid: "2-1",
                title: "章节维护",
                route: "/course/management/classHour",
                path: 'pages/course/management/classHour',// 组件路径
                type: '2',
                isRoute: '0',
            },
            {
                id: "2-2",
                pid: "2",
                title: "课程分类",
                route: "/course/classification",
                path: 'pages/course/classification',// 组件路径
                type: '2',
                isRoute: '1',
            },
            {
                id: "3",
                pid: "0",
                route: "/order",
                title: "订单管理",
                icon: "SettingFilled",
                type: '1',
                isRoute: '1',
            },
            {
                id: "3-1",
                pid: "3",
                route: "/order/list",
                path: 'pages/order/list',// 组件路径
                title: "订单列表",
                type: '2',
                isRoute: '1',
            },
            {
                id: "5",
                pid: "0",
                route: "/goods",
                title: "商品管理",
                icon: "SettingFilled",
                type: '1',
                isRoute: '1',
            },
            {
                id: "5-1",
                pid: "5",
                title: "商品列表",
                route: "/goods/management",
                path: 'pages/goods/management',// 组件路径
                type: '2',
                isRoute: '1',
            },
            {
                id: "5-1-1",
                pid: "5-1",
                title: "章节维护",
                route: "/goods/management/classHour",
                path: 'pages/goods/management/classHour',// 组件路径
                type: '2',
                isRoute: '0',
            },
            {
                id: "5-2",
                pid: "5",
                title: "商品分类",
                route: "/goods/classification",
                path: 'pages/goods/classification',// 组件路径
                type: '2',
                isRoute: '1',
            },
            {
                id: "6",
                pid: "0",
                route: "/resources",
                title: "资源管理",
                icon: "SettingFilled",
                type: '1',
                isRoute: '1',
            },
            {
                id: "6-1",
                pid: "6",
                title: "资源列表",
                route: "/resources/management",
                path: 'pages/resources/management',// 组件路径
                type: '2',
                isRoute: '1',
            },
            {
                id: "6-2",
                pid: "6",
                title: "资源分类",
                route: "/resources/classification",
                path: 'pages/resources/classification',// 组件路径
                type: '2',
                isRoute: '1',
            },
            {
                id: "4",
                pid: "0",
                route: "/system",
                title: "系统设置",
                icon: "SettingFilled",
                type: '1',
                isRoute: '1',
            },
            {
                id: "4-1",
                pid: "4",
                route: "/system/user",
                path: 'pages/system/user',// 组件路径
                title: "管理员用户管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-2",
                pid: "4",
                route: "/system/personalUser",
                path: 'pages/system/personalUser',// 组件路径
                title: "个人用户管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-3",
                pid: "4",
                route: "/system/companyUser",
                path: 'pages/system/companyUser',// 组件路径
                title: "企业用户管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-4",
                pid: "4",
                route: "/system/userLevel",
                path: 'pages/system/userLevel',// 组件路径
                title: "用户等级管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-5",
                pid: "4",
                route: "/system/role",
                path: 'pages/system/role',// 组件路径
                title: "角色管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-6",
                pid: "4",
                route: "/system/permissions",
                title: "权限管理",
                type: '1',
                isRoute: '1',
            },
            {
                id: "4-6-1",
                pid: "4-6",
                route: "/system/permissions/api",
                path: 'pages/system/permissions/api',// 组件路径
                title: "接口管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-6-2",
                pid: "4-6",
                route: "/system/permissions/menu",
                path: 'pages/system/permissions/menu',// 组件路径
                title: "菜单管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-6-3",
                pid: "4-6",
                route: "/system/permissions/element",
                path: 'pages/system/permissions/element',// 组件路径
                title: "元素管理",
                type: '2',
                isRoute: '1',
            },
            {
                id: "4-7",
                pid: "4",
                route: "/system/dict",
                path: 'pages/system/dict',// 组件路径
                title: "数据字典",
                type: '2',
                isRoute: '1',
            },
            // {
            //     id: "999",
            //     pid: "0",
            //     title: "测试",
            //     icon: "SettingFilled",
            //     route: "/test",
            //     path: 'pages/test',// 组件路径
            //     type: '2',
            //     isRoute: '1',
            // },
            // {
            //     id: "888",
            //     pid: "0",
            //     title: "demo",
            //     icon: "SettingFilled",
            //     route: "/demo",
            //     path: 'pages/demo',// 组件路径
            //     type: '2',
            //     isRoute: '1',
            // },
        ];
        dispatch(setMenusList(data));
        return true;
    };
    const runFun = async () => {
        const userInfoRes = await userInfoRequest.run();
        if (userInfoRes?.status === 'success' && userInfoRes?.data) {
            const { userCompany, userPerson, id, userName: username, userType, skin } = userInfoRes?.data || {};
            // 1 管理员 2 企业 3个人
            const userInfo = userType === 1 || userType === 3 ? userCompany : userPerson;
            let userInfoData: UserInfoType = {
                id,
                username,
                userType,
                trueName: userInfo?.trueName,
                sex: userInfo?.sex,
                age: userInfo?.age,
                mobile: userInfo?.mobile,
                idCard: userInfo?.idCard,
                email: userInfo?.email,
                bank: userInfo?.bank,
                // bankBranch: userInfo?.bankBranch,
                bankAccount: userInfo?.bankAccount,
                alipayAccount: userInfo?.alipayAccount,
                companyName: userInfo?.companyName,
                contactPerson: userInfo?.contactPerson,
                contactPersonMobile: userInfo?.contactPersonMobile,
                creditCode: userInfo?.creditCode,
            };
            dispatch(setUserInfo(userInfoData));
            const navigationMode: NavigationModeType = skin && JSON.parse(skin);
            navigationMode && dispatch(setNavigationMode(navigationMode));
        }
        const userMenusRes = await getUserMenus();
        const dictAllRes = await dictAllRequest.run();
        if (dictAllRes?.status === 'success' && dictAllRes?.data) {
            dispatch(setDictAllData(dictAllRes?.data || []));
        }
        return userInfoRes?.status === 'success' && userMenusRes && dictAllRes?.status === 'success';
    };
    return { runFun };
};
export default Index;