/*
 * @Author: shen
 * @Date: 2020-09-14 08:38:14
 * @LastEditors: tanwei
 * @LastEditTime: 2020-11-08 19:58:38
 * @Description: global config
 */

interface Config {
    title: string,
    menuTitle: string,
    apiWebPath: string,
    apiAdminPath: string,
}

const config: Config = {
    title: '开放平台后管',
    menuTitle: '开放平台后管',
    apiWebPath: '/open',
    apiAdminPath: '/admin',
};

export {
    config as default,
};
