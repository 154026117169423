/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-08-09 11:42:22
 * @LastEditors: tanwei
 * @LastEditTime: 2020-11-24 16:32:40
 * @FilePath: /open-platform/src/redux/actionTypes.ts
 */


export const MENU_COLLAPSED = "MENU_COLLAPSED";
export type MENU_COLLAPSED = typeof MENU_COLLAPSED

export const USER_INFO = "USER_INFO";
export type USER_INFO = typeof USER_INFO

export const DICT_ALL_DATA = "DICT_ALL_DATA";
export type DICT_ALL_DATA = typeof DICT_ALL_DATA

export const MENU_LIST = "MENU_LIST";
export type MENU_LIST = typeof MENU_LIST

export const NAVIGATION_MODE = "NAVIGATION_MODE";
export type NAVIGATION_MODE = typeof NAVIGATION_MODE

export const IS_All_FULL_SCREEN = "IS_All_FULL_SCREEN";
export type IS_All_FULL_SCREEN = typeof IS_All_FULL_SCREEN


