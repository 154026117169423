import React, { FC, memo } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import PrivateRoute from './pravateRoute';
import { Helmet } from "react-helmet";
import config from '@/config';
export interface WrapperRouteProps extends RouteProps {
    title: string
    auth?: boolean
}

const WrapperRouteComponent: FC<WrapperRouteProps> = ({ title, auth, ...props }) => {
    const WitchRoute = auth ? PrivateRoute : Route;
    return (
        <>
            {title &&
                <Helmet>
                    <title>{title}-{config.title}</title>
                    <meta name="description" content={`${title}-${config.title}-description`} />
                </Helmet>
            }
            <WitchRoute {...props} />
        </>
    );
};

export default memo(WrapperRouteComponent);
