/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-25 13:06:20
 * @LastEditors: tanwei
 * @LastEditTime: 2021-02-21 19:50:31
 * @FilePath: /open-platform/src/components/FormElements/WrapperRadio.tsx
 */
import React, { FC, memo } from 'react';
import { ProFormRadio } from '@ant-design/pro-form';
import { RadioProps, RadioGroupProps } from 'antd/lib/radio';
import { ProSchema } from '@ant-design/pro-utils';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';
interface BaseRadioProps<T> extends ProFormItemProps<T> {
    radioType?: 'button' | 'radio';
    options?: RadioGroupProps['options'];
    layout?: 'horizontal' | 'vertical';
    valueEnum?: ProSchema['valueEnum'];
    request?: ProSchema['request'];
}
const WrapperRadio: FC<BaseRadioProps<RadioProps>> = ({ ...restProps }) => {
    return (
        <ProFormRadio.Group {...restProps} />
    );
};

export default memo(WrapperRadio);
