/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-11-09 14:56:19
 * @LastEditors: tanwei
 * @LastEditTime: 2020-11-09 17:28:19
 * @FilePath: /open-platform/src/mock/config.js
 */
import Mock from 'mockjs';

Mock.setup({
    timeout: '1000-3000'
});