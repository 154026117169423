/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-11-09 15:05:13
 * @LastEditors: tanwei
 * @LastEditTime: 2021-03-13 19:11:32
 * @FilePath: /open-platform/src/mock/index.js
 */

import './config';
import './course';
import './courseClassification';
