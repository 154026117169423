/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-11-26 16:56:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-23 23:56:29
 * @FilePath: /open-platform-admin/src/components/ModalForm/index.tsx
 */
import React, { FC } from 'react';
import ProForm from '@ant-design/pro-form';
import WrapperModal from '@/components/WrapperModal';
import { ModalProps } from 'antd/lib/modal';
import { ProFormProps } from '@ant-design/pro-form/lib/index';
import WrapperForm from '@/components/FormElements/WrapperForm';
interface IProps {
    /** 提交表单且数据验证成功后回调事件 */
    onFinish?: (values: any) => Promise<void>;
    /** antd modal配置 */
    modalConfig: ModalProps
    /** antd Pro form 配置 */
    formConfig?: ProFormProps
}
const Index: FC<IProps> = ({ children, onFinish, modalConfig, formConfig }) => {
    const [form] = ProForm.useForm();
    const modalOnFinish = () => {
        const TForm = formConfig?.form;
        const newForm = TForm ? TForm : form;
        newForm.validateFields()
            .then(values => {
                onFinish && onFinish(values as any);
            })
            .catch(errorInfo => {
                console.log(errorInfo, 'errorInfo');
            });
    };
    return (
        <WrapperModal
            onOk={modalOnFinish}
            {...modalConfig}
        >
            <WrapperForm
                {...formConfig}
            >
                {children}
            </WrapperForm>
        </WrapperModal>
    );
};
export default Index;