/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-25 13:06:20
 * @LastEditors: tanwei
 * @LastEditTime: 2021-03-07 17:48:05
 * @FilePath: /open-platform/src/components/FormElements/WrapperTreeSelect.tsx
 */
import React, { FC, memo } from 'react';
import { TreeSelect, Form } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';
export interface BaseTreeSelectProps<T> extends ProFormItemProps<T> {

}
const WrapperRadio: FC<BaseTreeSelectProps<TreeSelectProps<any>>> = (props) => {
    const { children, placeholder, width, secondary, disabled, allowClear, fieldProps, ...restProps } = props;
    return (
        <Form.Item
            {...restProps}
        >
            <TreeSelect
                placeholder={placeholder}
                allowClear={allowClear}
                disabled={disabled}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                {...fieldProps}
            >
                {children}
            </TreeSelect>
        </Form.Item>
    );
};

export default memo(WrapperRadio);
