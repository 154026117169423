/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-25 15:00:27
 * @LastEditors: tanwei
 * @LastEditTime: 2021-02-08 15:06:08
 * @FilePath: /open-platform/src/components/FormElements/WrapperInput.tsx
 */
import React, { FC } from 'react';
import { InputProps, PasswordProps, TextAreaProps } from 'antd/lib/input';
import {
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-form';
import { ProFormItemProps } from '@ant-design/pro-form/lib/interface';

const defaultConfig = {
    autoComplete: 'off',
    maxLength: 50,
};
interface BaseInputProps<T> extends ProFormItemProps<T> {
    autoComplete?: string
}
const WrapperInput: FC<BaseInputProps<InputProps>> = (props) => {
    const { fieldProps, ...restProps } = props;
    return <ProFormText
        {...restProps}
        fieldProps={{
            ...defaultConfig,
            ...fieldProps
        }}
    />;
};
const WrapperInputPassword: FC<BaseInputProps<PasswordProps>> = (props) => {
    const { fieldProps, ...restProps } = props;
    return <ProFormText.Password
        {...restProps}
        fieldProps={{
            ...defaultConfig,
            ...fieldProps
        }}
    />;
};


const defaultTextAreaConfig = {
    autoComplete: 'off',
    showCount: true,
    maxLength: 2000,
    autoSize: {
        minRows: 2,
        maxRows: 5,
    },
};
const WrapperInputTextArea: FC<BaseInputProps<TextAreaProps>> = (props) => {
    const { fieldProps, ...restProps } = props;
    return <ProFormTextArea
        {...restProps}
        fieldProps={{
            ...defaultTextAreaConfig,
            ...fieldProps
        }}
    />;
};
export {
    WrapperInput as default,
    WrapperInputPassword,
    WrapperInputTextArea,
};
