/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2020-10-29 15:46:44
 * @LastEditors: tanwei
 * @LastEditTime: 2021-01-29 10:42:38
 * @FilePath: /open-platform/src/router/pravateRoute.tsx
 */
import React, { FC, useEffect } from 'react';
import { getLocalStorageItem, clearAllLocalStorage } from '@/utils/utils';
import { Route, useNavigate } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { setNavigationMode } from '@/stores/actions/commonAction';
import { navigationModeIni } from '@/stores/reducers/commonReducer';
const PrivateRoute: FC<RouteProps> = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!getLocalStorageItem('token')) {
      window?.message?.warning('token过期或已失效，请重新登录');
      // 清除系统风格配置
      dispatch(setNavigationMode(navigationModeIni));
      clearAllLocalStorage();
      navigate('/login');
    }
  });

  return <Route {...props} />;
};

export default PrivateRoute;
